@tailwind base;
@tailwind components;
@tailwind utilities;

.bodyNegative {
    margin: -8px;
}

.loginContainer {
    padding: 50px 20px;
    font-family: sans-serif;
}

.bodyNegativeDesktop {
    margin: -8px;
    justify-content: center;
    display: flex;
}

.loginContainerDesktop {
    padding: 50px 20px;
    width: 500px;
    font-family: sans-serif;
}

.loginHeaderContainer {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 32px;
    color: #445DB7;
    line-height: 40px;
    font-weight: 700
}

.logoContainer {
    display: flex;
}

.inputContainer {
    width: 100%;
}

.inputContainerPass {
    width: 100%;
    margin-top: 5px;
    display: flex;
    border-radius: 6px;
    border: 1px solid #CBD5E1;
}

.inputLabel {
  font-size: 14px;
  color: #0F172A;
}

.inputLabel2 {
    font-size: 14px;
    color: #0F172A;
    margin-top: 20px;
  }
  
.inputField {
  width: 100%;
  height: 48px;
  border-radius: 6px;
  margin-top: 5px;
  padding-left: 10px;
  border: 1px solid #CBD5E1
}

.inputPassField {
  flex: 1;
  height: 48px;
  padding-left: 10px;
  background-color: transparent;
  border: 0px
}

.showPassword {
  line-height: 24px;
  padding-top: 12px;
  font-size: 14px;
  color: #00C46E;
  text-decoration: none;
  font-weight: 700;
  padding-right: 20px;
}

.forgotPasswordContainer {
    margin-top: 5px;
  }

.forgotPassword {
  line-height: 24px;
  font-size: 14px;
  color: #00C46E !important;
  text-decoration: none;
  font-weight: 700
}

.orContainer {
    display: flex;
    margin-top: 15px;
    justify-content: center;
    color: #94A3B8;
    font-size: 14px;
}

.logoImg {
    height: 40px
}

.loginButton {
  width: 100%;
  font-size: 16px;
  background-color: #25CE84;
  border-radius: 28px;
  color: #0F172A;
  border: 0px;
  height: 56px;
  font-weight: 700;
  margin-top: 20px
}

.hrStyleLeft {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  height: 1px;
  margin-left: 0px;
  margin-right: 15px;
  width: 48%;
  border: 0px;
  background-color: #E2E8F0
}

.hrStyleRight {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  height: 1px;
  margin-left: 15px;
  margin-right: 0px;
  width: 48%;
  border: 0px;
  background-color: #E2E8F0
}

.socialButton {
  font-size: 14px;
  border-radius: 28px;
  color: #0F172A;
  border-width: 1px;
  border: 1px solid #25CE84;
  box-shadow: none;
  height: 56px;
  font-weight: 700;
  font-family: sans-serif;
  margin-top: 10px;
  background-color: transparent;
  width: 100%;
  display: inline-block;
}

.socialImg {
  float: left; 
  margin-left: 10px
}

.socialText {
  line-height: 24px;
  padding-right: 10px;
}

.socialButtonContainer {
  margin-top: 10px
}

.signupContainer {
    display: flex; 
    margin-top: 20px;
    justify-content: center;
    color: #94A3B8;
    font-size: 14px;
}


.signupText {
  line-height: 24px;
  font-weight: 200;
  color: rgba(15, 23, 42, .64)
}

.signupLink {
  text-decoration: underline;
  line-height: 24px;
  font-weight: 700;
  margin-left: 10px;
  color: #0F172A
}
